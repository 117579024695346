import { CogIcon } from '@heroicons/react/24/outline';
import { ProgramAPI } from '@platform/api';
import { Constants } from '@platform/app-config';
import { assertUnreachableSafe, buildAPIUrl, CampaignMedium } from '@platform/helpers';
import { Breadcrumbs } from '@platform/ui';
import { Portal } from '@platform/ui-helpers';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Selectors } from '../../redux/selectors';
import { useTypedDispatch } from '../../redux/state';
import { loadPrograms } from '../../redux/thunks';
import { withAuthenticatedPageLayout } from '../Layout/authenticated-page';
import { ProgramPreview } from './program-preview';

export const ProgramSettingsDetail = withAuthenticatedPageLayout((props) => {
  const dispatch = useTypedDispatch();
  const { programs, isLoaded } = useSelector(Selectors.programs);
  const { user } = useSelector(Selectors.user);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoaded) {
      dispatch(loadPrograms(props.auth0.getAccessTokenSilently));
    }
  }, [dispatch, isLoaded, props.auth0.getAccessTokenSilently]);

  const program = useMemo(
    () => (!!id && programs.length ? programs.find((p) => p.id === Number(id)) : undefined),
    [programs, id]
  );

  return (
    <div>
      <div className="bg-white shadow sticky top-0 z-10">
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="pt-0 pb-6 lg:py-6 flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="flex-1 min-w-0">
              <div className="mt-0 py-2 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <Breadcrumbs
                    icon={
                      <CogIcon
                        className="transform -rotate-45 text-gray-400 -mt-0.5 -mr-1.5 flex-shrink-0 h-5 w-5"
                        aria-hidden="true"
                      />
                    }
                    pages={[
                      {
                        name: 'Automations',
                        onClick: () => navigate(Portal.getRouteName('/program-settings')),
                      },
                      {
                        name: program?.name ?? '•••',
                      },
                    ]}
                  />
                </div>
              </div>
              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {program?.name ?? 'Loading...'}
              </h1>
              {!!program && (
                <div className="flex space-x-2 pt-4 items-center">
                  <div
                    className={classNames(
                      {
                        'text-gray-600 bg-gray-200': !program.activatedOn,
                        'text-white bg-green-400': !!program.activatedOn,
                      },
                      'rounded-2xl font-semibold py-1 px-3'
                    )}
                  >
                    {program.activatedOn ? 'Live' : 'Draft'}
                  </div>
                  {!!program.activatedOn && (
                    <div className="text-sm leading-5 font-normal text-gray-500">
                      Updated {moment(program.activatedOn).format('MMM D, YYYY')}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
              <span className="sm:ml-3">
                <a href={Constants.SUPPORT_FORM_PROGRAM_CHANGE_REQUEST} target="_blank" rel="noreferrer">
                  <button
                    type="button"
                    className="sm:text-base text-sm leading-4 font-semibold text-blue-600 h-[40px] sm:h-[52px] bg-white-500 hover:bg-gray-50 active:bg-blue-50 inline-flex justify-center items-center rounded-lg border border-gray-200 active:border-blue-500 hover:border-gray-200 px-3 sm:px-6 focus:outline-none mt-4"
                  >
                    Change Request
                  </button>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>

      {program && (
        <div className="px-4 py-5 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="w-2 h-2 bg-gray-400 m-auto my-2 rounded-lg"></div>
          {program.sendoutSpecification.map((s, i) => (
            <div key={i} className="flex flex-col">
              <div className="text-center items-center justify-center m-auto">
                <div className="w-0.5 h-6 bg-gray-300 m-auto my-2"></div>
                {s.timing === 'immediate'
                  ? 'No Delay'
                  : `Delay ${s.timing.value} ${
                      s.timing.value === 1
                        ? s.timing.unit === 'days'
                          ? 'day'
                          : s.timing.unit === 'weeks'
                          ? 'week'
                          : assertUnreachableSafe(s.timing.unit)
                        : s.timing.unit
                    }`}
                <div className="w-0.5 h-6 bg-gray-300 m-auto my-2"></div>
              </div>

              <div className="flex flex-col lg:flex-row">
                <div className="w-full">
                  <div className="shadow overflow-hidden rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="flex flex-col mb-6 w-full">
                        <div className="flex flex-row">
                          <h3 className="text-gray-700 text-xl font-bold grow">
                            {' '}
                            {s.medium === CampaignMedium.POSTCARD
                              ? '6x11" Postcard'
                              : s.medium === CampaignMedium.NOTE
                              ? 'Handwritten Note'
                              : s.medium === CampaignMedium.SURVEY
                              ? 'Survey'
                              : assertUnreachableSafe(s.medium) ?? s.medium}
                          </h3>
                          {(CampaignMedium.POSTCARD === s.medium || CampaignMedium.NOTE === s.medium) && (
                            <div className="flex space-x-2">
                              <a
                                href={
                                  s.medium === CampaignMedium.POSTCARD
                                    ? buildAPIUrl(process.env.REACT_APP_PROGRAM_API_URL!, {
                                        route: ProgramAPI.Public.API['GET /program/:id/preview/postcard/:sendout'],
                                        params: { id: program.externalTrackingId, sendout: i.toString() },
                                      })
                                    : s.medium === CampaignMedium.NOTE
                                    ? buildAPIUrl(process.env.REACT_APP_PROGRAM_API_URL!, {
                                        route: ProgramAPI.Public.API['GET /program/:id/preview/note'],
                                        params: { id: program.externalTrackingId },
                                      })
                                    : assertUnreachableSafe(s.medium)
                                }
                                className={
                                  'md:inline-flex hidden px-2 items-center border border-transparent text-sm font-normal rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                Preview
                              </a>
                              {user?.isSuperAdmin && s.medium === CampaignMedium.POSTCARD && (
                                <a
                                  href={buildAPIUrl(process.env.REACT_APP_PROGRAM_API_URL!, {
                                    route: ProgramAPI.Public.API['GET /program/:id/pcm_preview/postcard/:sendout'],
                                    params: { id: program.externalTrackingId, sendout: i.toString() },
                                  })}
                                  className={
                                    'md:inline-flex hidden px-2 items-center border border-transparent text-sm font-normal rounded-md shadow-sm text-black bg-gray-300 hover:bg-gray-200 focus:outline-none'
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  PCM Preview
                                </a>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {[CampaignMedium.NOTE, CampaignMedium.POSTCARD].includes(s.medium) && (
                        <div className="grid gap-2 items-top sm:grid-flow-col sm:auto-cols-max auto-rows-max grid-flow-row">
                          <ProgramPreview
                            key={`${i}-front`}
                            medium={s.medium}
                            side="front"
                            sendoutIdx={i}
                            programExternalId={program.externalTrackingId}
                            programApiUrl={process.env.REACT_APP_PROGRAM_API_URL!}
                          />
                          <div className="flex flex-col">
                            <ProgramPreview
                              key={`${i}-back`}
                              medium={s.medium}
                              side="back"
                              sendoutIdx={i}
                              programExternalId={program.externalTrackingId}
                              programApiUrl={process.env.REACT_APP_PROGRAM_API_URL!}
                            />
                            {s.medium === CampaignMedium.NOTE && (
                              <p className="text-xs font-light italic w-[3.5in]">
                                Note: Font spacing on preview may not be accurate. Spacing is dynamic based on the
                                character count.
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex flex-col text-center my-16">
            <h2 className="mt-2 text-3xl font-extrabold text-gray-900">Need updates?</h2>
            <p className="mt-1 text-sm text-gray-500">Submit a change request</p>
            <div className="mt-6">
              <a href={Constants.SUPPORT_FORM_PROGRAM_CHANGE_REQUEST} target="_blank" rel="noreferrer">
                <button
                  type="button"
                  className="
                      border border-gray-300 text-gray-700 hover:bg-gray-200 hover:border-gray-300 transition-colors hover:shadow-sm active:shadow-none active:bg-gray-300

                      sm:text-base text-sm leading-4 font-semibold h-[40px] sm:h-[52px] inline-flex justify-center items-center rounded-lg  px-3 sm:px-6 focus:outline-none mt-4"
                >
                  Submit Change Request
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
