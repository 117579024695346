/* eslint-disable @typescript-eslint/no-redeclare */
import type { PortalAPI } from '@platform/api';
import { ActionType } from '@platform/helpers';

export type LoadedUser = Omit<PortalAPI.UserAPI.GetUserResponse, 'company'>;

export const UserActions = {
  loaded: (user: LoadedUser) =>
    ({
      type: 'User/loaded',
      payload: { ...user },
    } as const),
  loading: () =>
    ({
      type: 'User/loading',
      payload: { isLoading: true },
    } as const),
  logout: () =>
    ({
      type: 'User/logout',
    } as const),
  update: (payload: PortalAPI.UserAPI.UpdateUserRequest) =>
    ({
      type: 'User/update',
      payload,
    } as const),
};

export type UserActions = ActionType<typeof UserActions>;
